/* @media (min-width: 768px) {
    .md\:w-4\/12 {
        width: 24.333%;
    }
} */

.text-yellow-650{
    color: white;
    background-color: green;
}
